import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import React, { useContext } from 'react';
import { AppState, AppStateData } from '../state/app.state';

export interface IFirebaseService {
    login(): Promise<void>;
    load(onSuccess: (data: AppStateData) => void): void;
    addCache(file: File): void;
    get(resource: string): string;
    getAppData(): AppStateData;
    publish(state: AppState): Promise<void>,
}
class FirebaseService implements IFirebaseService {
    private storage: firebase.storage.Reference;
    private database: firebase.database.Reference;
    private googleAuth: firebase.auth.GoogleAuthProvider;
    private cache: StorageCache = {};
    private token: string|undefined;
    private data: AppStateData|undefined;
    public constructor() {
        firebase.initializeApp({
            apiKey: "AIzaSyATWgrAaH9TlGBhhYY3t_KZe2bQi-s9hKI",
            projectId: "hadas-325c1",
            authDomain: 'hadas-325c1.firebaseapp.com',
            appId: "1:642730927019:web:c45ed9906e024c449228ee",
            storageBucket: "hadas-325c1.appspot.com",
            databaseURL: "https://hadas-325c1.firebaseio.com"
        });
        this.storage = firebase.storage().ref();
        this.database = firebase.database().ref();
        this.googleAuth = new firebase.auth.GoogleAuthProvider();
    }
    private getResources(state: AppStateData): string[] {
        let result: string[] = [];
        let items: any[] = [state];
        while(items.length > 0) {
            let o = items[0];
            Object.keys(o).forEach(k => {
                let item = o[k];
                if (item._type === 'IMAGE' || item._type === 'VIDEO') {
                    result.push(item.link);
                } else if (typeof(item) === 'object') {
                    items.push(item);
                }
            });
            items.shift();
        }

        return result.filter((e, i, a) => a.indexOf(e) === i);
    }
    public async login() {
        return await firebase.auth().signInWithPopup(this.googleAuth)
            .then((result: any) => {
                this.token = result.credential.accessToken;
            })
            .catch(error => alert(`Error al authenticarse: ${error}`))
    }
    public load(onSuccess: (state: AppStateData) => void) {
        this.database.on('value', async (data) => {
            this.data = data.val();
            if (this.data !== undefined) {
                let resources = this.getResources(this.data);
                await Promise.all(
                    resources.map(async resource => await this.getFileLink(resource))
                );
                onSuccess(this.data);
            }
        });
    }
    public addCache(file: File): void {
        this.cache[file.name] = URL.createObjectURL(file);
    }
    public get(resource: string): string {
        return this.cache[resource];
    }
    public async publish(state: AppState): Promise<void> {
        console.log("Publish", state);
        state.newResources.forEach(async f => {
            console.log("Store file: ", f.name);
            await this.storage.child(f.name).put(f, {
                cacheControl: 'public, max-age=31536000'
            });
        });
        await this.database.set(state.data);
        let resources = this.getResources(state.data!);
        let actual = await this.storage.listAll()
        actual.items
            .filter(i => !resources.includes(i.name))
            .forEach(async e => {
                console.log("Remove", e);
                await e.delete();
            });
    }
    public getAppData(): AppStateData {
        return this.data!;
    }
    private async getFileLink(name: string): Promise<string> {
        if (process.env.REACT_APP_FROM_FIREBASE === 'false') {
            let link = `/assets/${name}`;
            this.cache[name] = link;
            return link;
        } else {
            let resource = this.storage.child(name);
            /*
            await resource.updateMetadata({
                cacheControl: 'public, max-age=31536000'})
            .then(() => console.log(`Cache updated for ${name}`))  // TODO: remove cache
            .catch(error => console.log(`Error caching ${name}: ${JSON.stringify(error)}`));
            */
            return await resource.getDownloadURL()
                .then(link => this.cache[name] = link)
                .catch(_ => {
                    console.log(`Error loading image ${name}`);
                    this.cache[name] = `/assets/${name}`;
                });
        }
    }
}

const firebaseServiceContext = React.createContext<IFirebaseService>(new FirebaseService());

export function useFirebase() {
    return useContext(firebaseServiceContext);
}

type StorageCache = {
    [key:string]: string;
}

/*
const defaultData: AppStateData = {
    header: {
        logo: { _type: 'IMAGE', link: 'logo-md.png'},
        social: {
            instagram: { _type: 'LINK', url: 'https://www.instagram.com/hadasestudiodedanza/' },
            facebook: { _type: 'LINK', url: 'https://www.facebook.com/pages/category/Artist/Hadas-Estudio-de-Danza-Mayra-Malfitano-144710126050518/' },
            whatsapp: { _type: 'LINK', url: 'https://api.whatsapp.com/send?phone=5491140222619' }
        },
        labels: {
            inicio: { _type: 'STRING', value: 'INICIO' },
            profes: { _type: 'STRING', value: 'PROFES' },
            clases: { _type: 'STRING', value: 'CLASES' },
            salas: { _type: 'STRING', value: 'SALAS' },
            alumnos: { _type: 'STRING', value: 'TESTIMONIOS' },
            shows: { _type: 'STRING', value: 'SHOWS' },
            fotos: { _type: 'STRING', value: 'FOTOS' },
            contacto: { _type: 'STRING', value: 'INSCRIPCIÓN' }
        },
        image: { _type: 'IMAGE', link: 'header-default.jpg' }
    },
    footer: {
        logo: { _type: 'IMAGE', link: 'logo-md.png' },
        background: { _type: 'IMAGE', link: 'footer.jpg'},
        address: {
            tel: { _type: 'STRING', value: '15 4022 2619' },
            email: { _type: 'STRING', value: 'info.hadasestudiodedanza@gmail.com' },
            address1: { _type: 'STRING', value: 'Formosa 4931, Villa Ballester. Buenos Aires.' },
        }
    },
    inicio: {
        title: { _type: 'TEXT', parts: [{ _type: 'RAW_TEXT', text: '¡Hola! Te invito a conocernos!'}] },
        video: {
            image: { _type: 'IMAGE', link: 'home-video-background.jpg' },
            source: { _type: 'VIDEO', link: '60fpscomp.mp4' }
        },
        text: { _type: 'TEXT', parts: [
            { _type: 'RAW_TEXT', text: 'En ' },
            { _type: 'STYLED_TEXT', color: 'bold', text: 'Hadas ' },
            { _type: 'RAW_TEXT', text: 'queremos que encuentres un espacio de aprendizaje, amor y disfrute!!' },
            { _type: 'BREAK' },
            { _type: 'RAW_TEXT', text: 'Contamos con un Staff de profesoras comprometidas y apasionadas en el ' },
            { _type: 'STYLED_TEXT', color: 'primary', text: 'ACOMPAÑAMIENTO ' },
            { _type: 'RAW_TEXT', text: 'de cada alumna/o, además de las instalaciones y materiales requeridos para cada técnica y tu ' },
            { _type: 'STYLED_TEXT', color: 'secondary', text: 'BIENESTAR!' }
        ]}
    },
    shows: {
        header: { _type: 'STRING', value: 'SHOWS COREOGRÁFICOS Y PARTICIPATIVOS PARA EVENTOS' },
        title: { _type: 'TEXT', parts: [
            { _type: 'RAW_TEXT', text:'¡QUE TU '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'DÍA ESPECIAL '}, 
            { _type: 'RAW_TEXT', text:'SEA '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'INOLVIDABLE '}, 
            { _type: 'RAW_TEXT', text:'Y LLENO DE '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'DIVERSIÓN! '},
        ]},
        items: [
            {
                image: { _type: 'IMAGE', link: 'cumple-hadas.jpg' },
                media: [
                    { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/W3hUAyEpht8' },
                    { _type: 'IMAGE', link: 'cumple-hadas-1.jpg' }
                ],
                text: {
                    title: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'primary', text:'FESTEJÁ TU CUMPLE EN HADAS'}
                    ]},
                    body: { _type: 'TEXT', parts: [
                        { _type: 'RAW_TEXT', text:'Diseñamos una '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'clase exclusiva '},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'para vos y tus invitadas/os en Hadas.'},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'Para que tengas un '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'espacio cómodo '},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'y puedan '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'disfrutar en movimiento.'},
                    ]},
                }
            },
            {
                image: { _type: 'IMAGE', link: 'cumple-infantiles.jpg' },
                media: [
                    { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/SxyyE8Oun30' },
                    { _type: 'IMAGE', link: 'cumple-infantiles-1.jpg' }
                ],
                text: {
                    title: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'secondary', text:'CUMPLEAÑOS INFANTILES'}
                    ]},
                    body: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'bold', text:'Shows coreográficos '},
                        { _type: 'RAW_TEXT', text:'con la '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'temática '},
                        { _type: 'RAW_TEXT', text:'que más te guste '},
                        { _type: 'BREAK' },
                        { _type: 'STYLED_TEXT', color:'bold', text:'Juegos y baile '},
                        { _type: 'RAW_TEXT', text:'para que tus invitadas/os '},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'la pasen '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'super bien.'},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'¡Y también podemos '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'preparar una coreografía '},
                        { _type: 'BREAK' },
                        { _type: 'STYLED_TEXT', color:'bold', text:'con el/la cumpleañero/a '},
                        { _type: 'RAW_TEXT', text:'para que baile '},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'con nuestro equipo, sus amigas/os o familia!'},
                    ]}
                },
            },
            {
                image: { _type: 'IMAGE', link: 'casamientos.jpg' },
                media: [
                    { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/7TUgbJAXVmY' },
                    { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/S3IqLI2q7MQ' },
                    { _type: 'IMAGE', link: 'casamientos-1.jpg' },
                    { _type: 'IMAGE', link: 'casamientos-2.jpg' },
                    { _type: 'IMAGE', link: 'casamientos-3.jpg' }
                ],
                text: {
                    title: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'primary', text:'FIESTA DE 15 Y CASAMIENTOS'}
                    ]},
                    body: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'bold', text:'Shows coreográficos '},
                        { _type: 'RAW_TEXT', text:'con la '}, 
                        { _type: 'STYLED_TEXT', color:'bold', text:'temática '},
                        { _type: 'RAW_TEXT', text:'que elijas.'},
                        { _type: 'BREAK' },
                        { _type: 'STYLED_TEXT', color:'bold', text:'Juegos y baile '},
                        { _type: 'RAW_TEXT', text:'para que tus invitadas/os '},
                        { _type: 'BREAK' },
                        { _type: 'STYLED_TEXT', color:'bold', text:'participen y se diviertan.'},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'¡Incluso podemos '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'preparar una coreografía '},
                        { _type: 'BREAK' },
                        { _type: 'STYLED_TEXT', color:'bold', text:'con el/la agasajado/a,'},
                        { _type: 'BREAK' },
                        { _type: 'RAW_TEXT', text:'para que '},
                        { _type: 'STYLED_TEXT', color:'bold', text:'baile '},
                        { _type: 'RAW_TEXT', text:'con nuestro equipo, sus amigas/os o familia!'},
                    ]}
                }
            }
        ]
    },
    salas: {
        header: { _type: 'STRING', value: 'LAS SALAS SE ALQUILAN' },
        title: { _type: 'TEXT', parts: [
            { _type: 'RAW_TEXT', text: 'Las ' },
            { _type: 'STYLED_TEXT', color:'primary', text:'SALAS'},
            { _type: 'RAW_TEXT', text: 'se ' },
            { _type: 'STYLED_TEXT', color:'primary', text:'ALQUILAN'},
            { _type: 'RAW_TEXT', text: 'para ' },
            { _type: 'STYLED_TEXT', color:'primary', text:'ENSAYOS'},
            { _type: 'RAW_TEXT', text:', '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'SEMINARIOS'}, 
            { _type: 'RAW_TEXT', text: ', para ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'PROFESORAS/ES'},
            { _type: 'RAW_TEXT', text: ', que necesiten un espacio para dar ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'CLASES.'},
            { _type: 'BREAK' },
            { _type: 'RAW_TEXT', text: 'También para '},
            { _type: 'STYLED_TEXT', color:'primary', text:'SESIONES DE FOTO'},
            { _type: 'RAW_TEXT', text:', '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'VIDEO '},
            { _type: 'RAW_TEXT', text: 'y ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'ESTÉTICA.'},
        ]},
        items: [
            {
                image: { _type: 'IMAGE', link: 'room-2.jpg' },
                text: {
                    title: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'secondary', text:'7.5 MTS X 4 MTS'}
                    ]},
                    items: [
                        { _type: 'STRING', value: 'PISO FLOTANTE DE MADERA' },
                        { _type: 'STRING', value: 'BARRA Y ESPEJOS' },
                        { _type: 'STRING', value: 'EQUIPO DE SONIDO' },
                        { _type: 'STRING', value: 'AIRE ACONDICIONADO' }
                    ]
                }
            },
            {
                image: { _type: 'IMAGE', link: 'room-1.jpg' },
                text: {
                    title: { _type: 'TEXT', parts: [
                        { _type: 'STYLED_TEXT', color:'primary', text:'11.5 MTS X 7.4 MTS'}
                    ]},
                    items: [
                        { _type: 'STRING', value: 'PISO FLOTANTE DE MADERA' },
                        { _type: 'STRING', value: 'BARRA Y ESPEJOS' },
                        { _type: 'STRING', value: 'EQUIPO DE SONIDO' },
                        { _type: 'STRING', value: 'VENTILADORES Y RADIADORES' },
                    ]
                }
            }
        ],
        bottom: {
            title: { _type: 'TEXT', parts: [
                { _type: 'STYLED_TEXT', color:'secondary', text:'APTAS PARA DANZA, FITNESS Y ACROBACIA EN TELA'}
            ]},
            body: { _type: 'TEXT', parts: [
                { _type: 'STYLED_TEXT', color:'default', text:'DISPENSER DE AGUA FRÍA Y CALIENTE'},
                { _type: 'BREAK' },
                { _type: 'STYLED_TEXT', color:'default', text:'2 BAÑOS'},
                { _type: 'BREAK' },
                { _type: 'STYLED_TEXT', color:'default', text:'COBERTURA MÉDICA'},
            ]}
        }  
    },
    clases: {
        header: { _type: 'STRING', value: 'CLASES' },
        title: { _type: 'TEXT', parts: [
            { _type: 'RAW_TEXT', text: 'Nuestro ' },
            { _type: 'STYLED_TEXT', color:'primary', text:'OBJETIVO '},
            { _type: 'RAW_TEXT', text:'es que puedas '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'APRENDER '}, 
            { _type: 'RAW_TEXT', text: 'y ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'PROGRESAR '},
            { _type: 'RAW_TEXT', text: 'en la clase que elijas, ' },
            { _type: 'BREAK' },
            { _type: 'RAW_TEXT', text: 'que te sientas ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'LIBRE '},
            { _type: 'RAW_TEXT', text: ','},
            { _type: 'STYLED_TEXT', color:'primary', text:'FELIZ '},
            { _type: 'RAW_TEXT', text:'con un cuerpo más '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'ENTRENADO '},
            { _type: 'RAW_TEXT', text: 'y ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'MIMADO. '},
            { _type: 'BREAK' },
            { _type: 'RAW_TEXT', text: 'Que puedas investigar tu ' },
            { _type: 'STYLED_TEXT', color:'primary', text:'CREATIVIDAD '},
            { _type: 'RAW_TEXT', text:'y darle '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'LUZ '}, 
            { _type: 'RAW_TEXT', text: 'a tu propia/o ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'ARTISTA INTERIOR. '},
            { _type: 'BREAK' },
            { _type: 'RAW_TEXT', text: 'Lograr ' },
            { _type: 'STYLED_TEXT', color:'secondary', text:'AUTOCONFIANZA '},
            { _type: 'RAW_TEXT', text: ', compartir en '},
            { _type: 'STYLED_TEXT', color:'primary', text:'EQUIPO '},
            { _type: 'RAW_TEXT', text:'y sobre todo: '},
            { _type: 'STYLED_TEXT', color:'secondary', text:'¡DISFRUTAR! '},
        ]},
        groups: [
            {
                name: { _type: 'STRING', value: 'INICIACIÓN - 3 a 5 años' },
                classes: [
                    {
                        name: { _type: 'STRING', value: 'INICIACIÓN AL POP DANCE' },
                        image: { _type: 'IMAGE', link: 'pop-dance-iniciacion.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'3 a 5 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Rosario Rodriguez Molina.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'INICIACIÓN A LA ACROBACIA' },
                        image: { _type: 'IMAGE', link: 'classes-1.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'3 a 5 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Acrobacia en Tela + Gimnasia Artística.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    }
                ]
            },
            {
                name: { _type: 'STRING', value: 'INFANTILES  – 6 a 9 años –' },
                classes: [
                    {
                        name: { _type: 'STRING', value: 'POP DANCE' },
                        image: { _type: 'IMAGE', link: 'pop-dance-infantiles.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'6 a 9 años'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Rosario Rodriguez Molina.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'URBAN' },
                        image: { _type: 'IMAGE', link: 'urban-infantiles.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'6 a 9 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Julieta Midun y Mayra Malfitano.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'ACROBACIA EN TELA' },
                        image: { _type: 'IMAGE', link: 'classes-2.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'6 a 9 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'GIMNASIA ARTÍSTICA' },
                        image: { _type: 'IMAGE', link: 'gimnasia-artistica-infantiles.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'6 a 9 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    }
                ]
            },
            {
                name: { _type: 'STRING', value: 'PRE JUVENILES – 10 a 13 años –' },
                classes: [
                    {
                        name: { _type: 'STRING', value: 'POP DANCE' },
                        image: { _type: 'IMAGE', link: 'pop-dance-prejuveniles.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'10 a 13 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Rosario Rodriguez Molina.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'URBAN' },
                        image: { _type: 'IMAGE', link: 'urban-prejuveniles.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'10 a 13 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Julieta Midun y Mayra Malfitano.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'ACROBACIA EN TELA' },
                        image: { _type: 'IMAGE', link: 'classes-3.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'10 a 13 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'GIMNASIA ARTÍSTICA' },
                        image: { _type: 'IMAGE', link: 'gimansia-artistica-prejuveniles.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'10 a 13 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    }
                ]
            },
            {
                name: { _type: 'STRING', value: 'JUVENILES Y ADULTAS/OS – A partir de 14 años –' },
                classes: [
                    {
                        name: { _type: 'STRING', value: 'DANZA CONTEMPORÁNEA' },
                        image: { _type: 'IMAGE', link: 'danza-contemporanea-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 16 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Mayra Malfitano.'}
                        ]},
                        video: { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/mqZbRUrgnWk' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'JAZZ CONTEMPORÁNEO' },
                        image: { _type: 'IMAGE', link: 'jazz-contemporaneo-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'Nivel Intermedio. A partir de 16 años. '}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Mayra Malfitano.'}
                        ]},
                        video: { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/0mpC3XdY7nI' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'POP DANCE' },
                        image: { _type: 'IMAGE', link: 'pop-dance-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 14 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Rosario Rodriguez Molina.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'REGGAETON' },
                        image: { _type: 'IMAGE', link: 'reggaeton-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 14 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Mayra Malfitano.'}
                        ]},
                        video: { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/BXTyt5-1adA' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'STREET' },
                        image: { _type: 'IMAGE', link: 'street-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 14 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Julieta Midun.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'POP LATINO' },
                        image: { _type: 'IMAGE', link: 'pop-latino-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 14 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Carola Cossi.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'SALSA Y BACHATA' },
                        image: { _type: 'IMAGE', link: 'salsa-bachata-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 16 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Carola Cossi.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'ACROBACIA EN TELA' },
                        image: { _type: 'IMAGE', link: 'acrobacia-en-tela-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 14 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'GIMNASIA ARTÍSTICA' },
                        image: { _type: 'IMAGE', link: 'gimnasia-artistica-juvenil-adulto.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 14 años.'}, 
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'. Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                        ]},
                        video: { _type: 'YOUTUBE', link: '' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'STRETCHING' },
                        image: { _type: 'IMAGE', link: 'stretching.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'A partir de 25 años.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente más información.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Rosario Rodriguez Molina.'}
                        ]},
                        video: { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/V1D95il6Hws' },
                        detail: { _type: 'TEXT', parts: [] }
                    },
                    {
                        name: { _type: 'STRING', value: 'TALLER DE ACROBACIA EN TELA CON ORIENTACIÓN PEDAGÓGICA' },
                        image: { _type: 'IMAGE', link: 'acrobacia-en-tela-orientacion-pedagogica.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'bold', text:'Nivel: Principiante. A partir de 16 años.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Dirigido a: Estudiantes de alguna carrera a fin, profesores de ed. física,'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'alumnas/os de alguna disciplina circense, gimnastas o bailarinas/es.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Prof. Lucila Ortiz.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'bold', text:'Próximamente fecha de inscripción.'}
                        ]},
                        video: { _type: 'YOUTUBE', link: 'https://www.youtube.com/embed/wm2a-GnOz08' },
                        detail: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'default', text:'Contenidos a trabajar:'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'Entrada en calor general y específica (juegos recreativos según etapa del alumnado,'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'circuitos para desarrollo de habilidades motoras básicas, trabajos en la tela,'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'exploración del elemento, etc.)'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'Preparación física y flexibilidad (ejercicios de fuerza, resistencia, velocidad, movilidad articular'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'y flexibilidad necesario para la realización de las técnicas acrobáticas)'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'Desarrollo de la clase: técnicas básicas (inversiones, subidas, nudos, ejercicios básicos),'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'metodologías (paso a paso para lograr una correcta ejecución), formas de cuidado'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'(rol docente al momento del cuidado del cuerpo del alumnado y el propio), errores comunes y variantes.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'Vuelta a la calma (elongación final, cierre de clase, juegos pasivos).'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'Todos los ejes temáticos separados por grupo de trabajo según edad, siempre nivel básico.'},
                            { _type: 'BREAK'},
                            { _type: 'STYLED_TEXT', color:'default', text:'Se entregará certificado de asistencia.'}
                        ]}
                    }
                ]
            },
        ] 
    },
    fotos: {
        header: { _type: 'STRING', value: 'ASÍ SE DISFRUTA EN FUNCIÓN HADAS!'},
        images: [
            { _type: 'IMAGE', link: 'fotos-1.jpg' },
            { _type: 'IMAGE', link: 'fotos-2.jpg' },
            { _type: 'IMAGE', link: 'fotos-3.jpg' },
            { _type: 'IMAGE', link: 'fotos-4.jpg' },
            { _type: 'IMAGE', link: 'fotos-14.jpg' },
            { _type: 'IMAGE', link: 'fotos-6.jpg' },
            { _type: 'IMAGE', link: 'fotos-7.jpg' },
            { _type: 'IMAGE', link: 'fotos-8.jpg' },
            { _type: 'IMAGE', link: 'fotos-9.jpg' },
            { _type: 'IMAGE', link: 'fotos-10.jpg' },
            { _type: 'IMAGE', link: 'fotos-11.jpg' },
            { _type: 'IMAGE', link: 'fotos-12.jpg' },
            { _type: 'IMAGE', link: 'fotos-13.jpg' },
            { _type: 'IMAGE', link: 'fotos-5.jpg' },
            { _type: 'IMAGE', link: 'fotos-15.jpg' },
            { _type: 'IMAGE', link: 'fotos-19.jpg' },
            { _type: 'IMAGE', link: 'fotos-17.jpg' },
            { _type: 'IMAGE', link: 'fotos-18.jpg' },
            { _type: 'IMAGE', link: 'fotos-16.jpg' },
            { _type: 'IMAGE', link: 'fotos-20.jpg' },
            { _type: 'IMAGE', link: 'fotos-26.jpg' },
            { _type: 'IMAGE', link: 'fotos-22.jpg' },
            { _type: 'IMAGE', link: 'fotos-23.jpg' },
            { _type: 'IMAGE', link: 'fotos-24.jpg' },
            { _type: 'IMAGE', link: 'fotos-25.jpg' },
            { _type: 'IMAGE', link: 'fotos-21.jpg' },
            { _type: 'IMAGE', link: 'fotos-27.jpg' },
            { _type: 'IMAGE', link: 'fotos-28.jpg' },
            { _type: 'IMAGE', link: 'fotos-29.jpg' },
            { _type: 'IMAGE', link: 'fotos-30.jpg' },
            { _type: 'IMAGE', link: 'fotos-31.jpg' },
            { _type: 'IMAGE', link: 'fotos-32.jpg' },
            { _type: 'IMAGE', link: 'fotos-33.jpg' },
            { _type: 'IMAGE', link: 'fotos-34.jpg' },
            { _type: 'IMAGE', link: 'fotos-35.jpg' },
            { _type: 'IMAGE', link: 'fotos-36.jpg' },
        ]
    },
    profes: {
        header: { _type: 'STRING', value: 'STAFF HADAS' },
        classes: { _type: 'STRING', value: 'ACÁ PODES VER TODAS LAS OPCIONES DE CLASES QUE TENEMOS PARA VOS' },
        coaches: [
            {
                name: { _type: 'STRING', value: 'Mayra Malfitano' },
                image: { _type: 'IMAGE', link: 'profe_mayra.jpg' },
                header: {
                    image: { _type: 'IMAGE', link: 'header-default.jpg' },
                    title: { _type: 'STRING', value: 'MAYRA MALFITANO' }
                },
                title: { _type: 'TEXT', parts: [
                    { _type: 'STYLED_TEXT', color: 'bold', text: '¡Hola! Soy Mayra Malfitano. Bailarina, docente, coreógrafa y directora de este sueño hecho realidad: Hadas' },
                ]},
                stories: [
                    {
                        image: { _type: 'IMAGE', link: 'coachview-mayra-1.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', size: 'md', color: 'primary', text: '¡Te invito a días de disfrute y mucho movimiento!' },
                            { _type: 'BREAK' },
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', size: 'md', color: 'bold', text: 'Vamos a acompañarte con amor y profesionalismo para que este espacio sea de aprendizaje, libertad, desafíos y muchas alegrías.' }
                        ]}
                    },
                    {
                        image: { _type: 'IMAGE', link: 'coachview-mayra-2.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Te cuento un poco cómo empezó todo… desde pequeña mi cuerpo pedía movimiento y'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'por suerte mi mamá y mi papá siempre me acompañaron'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'y realicé desde los 3 años actividades'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'como patín y gimnasia artística.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Mi habitación siempre estaba con el equipo de música a'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'full mientras bailaba. Y finalmente conocí las clases de danza.'}, { _type: 'BREAK' },
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Cuando tenía 16 años reemplacé durante 1 mes a mi profesora de danza jazz.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Fue el momento en el que me di cuenta que quería dedicarme a dar clases,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'ese espacio era único!'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Siempre me gustó enseñar,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'de niña eran mis peluches y mi hermano, de más grande mis compañeras/os de colegio.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'y la danza es algo que me apasiona y amo,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'cada vez que bailo me siento libre, feliz y renovada!'}
                        ]}
                    },
                    {
                        image: { _type: 'IMAGE', link: 'coachview-mayra-3.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'A los 18 años, al mismo tiempo que comencé la carrera de danza,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'empecé a dar clases en el Club que mi profesora había decidido dejar,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'y a los pocos meses alquilé un espacio pequeño'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'en una Asociación de Fomento al lado de mi casa.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Durante 1 mes salí a volantear e hice'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'silla hasta que llegaron 2 nenas. :)'}, { _type: 'BREAK' },
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'En mi segundo año de carrera, '}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'estando en mi casa estornudé y me quedé dura de la cintura,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'fue ahí cuando descubrimos que había nacido con una vértebra desplazada,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'el traumatólogo con total naturalidad me recomendó dedicarme a otra cosa.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Claramente eso no estaba en mis planes, empecé a investigar,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'hice tratamientos y con mucha paciencia, aprendizaje,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'amor y cuidado hoy puedo seguir trabajando de mi pasión.'}
                        ]}
                    },
                    {
                        image: { _type: 'IMAGE', link: 'coachview-mayra-4.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Al terminar la carrera, comencé a construir, literalmente, mi sueño. Lo que en el barrio se'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'conocía como la vieja fábrica de mosaicos de mis abuelos, volvió a cobrar vida con mucho movimiento!'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Un primero de marzo, los albañiles me tocaron el timbre para comenzar.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'El proceso fue duro, había mucho ladrillo por retocar, pero lo más lindo es que,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'como era de cero, Hadas iba a ser 100% a mi gusto.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Mi mamá, papá y novio me acompañaron mucho en'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'esta locura cada fin de semana (lijar, pintar, ordenar, limpiar y todas las'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'corridas que fueron surgiendo).'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Finalmente el 26 de Septiembre de 2010 Hadas abrió sus puertas, con el salón soñado, de'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'colores, enorme, con piso de madera flotante, barras por todos lados y espejos'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'de punta a punta.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Y cada año sumamos un granito de arena, hoy contamos con 2 salones, 2 baños, recepción,'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'el frente, el patio lleno de naturaleza.... un staff humano soñado y alumnas/os con muchas'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'ganas de aprender, compartir y disfrutar!'}, { _type: 'BREAK' },
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: '¡Te esperamos con los brazos abiertos para sumarte!'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Y por aquí abajo te cuento un poco de mis estudios y trayectoria:'}
                        ]}
                    }
                ],
                bottom: [
                    {
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Formación Integral en Danza. Escuela de Actividades Artísticas El Centro de Daniela Fernández.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Formación Integral en Comedia Musical. Escuela de Actividades Artísticas El Centro de Daniela Fernández.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Diplomatura en Danza. Universidad Nacional de San Martin “UNSAM”. Dirigida por Oscar Araiz.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Cátedra Danza Integradora. UNA, Profesora Susana González Gonz.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Perfeccionamiento en Broadway Dance Center. Julio 2015. Junio a Septiembre de 2018, Visa Program.'}, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: `- Instructorado de Ritmos Brasileros. Escuela de Fitness Internacional Adrián Andreani.
                                Profesorado de Reggaeton. Escuela de Ritmos Romina Samelnik.
                                Instructorado de Ritmos. Escuela de Ritmos Romina Samelnik.`},
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Seminario Intensivo Anual de Danza Integradora con orientación pedagógica. UNA, Prof. Susana Gonzalez Gonz.'},{ _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Seminarios y Convenciones de Ritmos, Danza Jazz, Femme Style, Dance Kids, Hip Hop Lyrical.'},{ _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Workshop Internacional Jazz Funk y Hip Hop. Nika Kljun, Cameron Lee, Camilo Lauricella, Mel Charlot.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Workshop Internacional Jazz Lyrical. Erica Sobol, Derek Mitchell, Kiki Lucas, Ashlé Dawson, Tracie Stanfield.' }
                        ]}
                    },
                    {
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'default', text: `
                                - Profesora en el Club Sportivo Villa Ballester, LAR Estudio, Estudio de Danza y 
                                Arte Reina Reech (Asistencias y Suplencias), Asociación de Cultura Alem, Escuela
                                especial 505.` }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Coreógrafa de Comedia Musical. Fila 1 Producciones, Colegio José Hernández.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Directora y Profesora en Hadas.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: `
                                - Bailarina en “Divas del Pop”, “El Mundo de Discovery Kids”, “Entre Príncipes y
                                Princesas”, “Mago Sebastián Senko”, “Compañía El Centro de Daniela Fernández”,
                                “Gira Internacional Lazy Town. Diwan Producciones”, “Christmas Show”,
                                “Compañía Alma, Danza Integradora”.` }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Participación en Eventos de Cultura organizados por la Municipalidad de General San Martin.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Directora y Bailarina en Hadas Producciones y Compañía Hadas.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Jurado en el Carnaval de Chaco.' }, { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'default', text: '- Disertante y profesora en Congreso Internacional de Danza Clásica y Danza Contemporánea, Villa Carlos Paz, Córdoba.' }
                        ]}
                    }
                ]
            },
            {
                name: { _type: 'STRING', value: 'Rosario Rodriguez Molina' },
                image: { _type: 'IMAGE', link: 'profe_rosario.jpg' },
                header: {
                    image: { _type: 'IMAGE', link: 'header-default.jpg' },
                    title: { _type: 'STRING', value: 'ROSARIO RODRIGUEZ MOLINA' }
                },
                title: { _type: 'TEXT', parts: [
                    { _type: 'STYLED_TEXT', color: 'bold', text: 'Rosario comenzó siendo alumna del estudio y es un orgullo tenerla hoy como parte del Staff.' },
                ]},
                stories: [
                    {
                        image: { _type: 'IMAGE', link: 'coachview-rosario-1.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Ella nos cuenta...'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'En mi familia siempre recuerdan que'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'de pequeña, a los 3 años,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'luego de cenar me subían sobre la mesa y me ponía a bailar “Ojos Así” de Shakira,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'todas las noches era el mismo ritual.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Luego cuando fui creciendo me empezó a encantar enseñar, esperaba a que mi papá'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'llegara de trabajar para jugar a la maestra con él.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'A los 9 años comencé con clases de danza árabe y a los 10 años me incorporé en las clases'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'que Mayra dictaba. Iba al colegio, luego al trabajo de mis papás para hacer la tarea,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'merendar y de ahí con muchas ganas iba a mis clases de danza. Así siguió toda mi'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'adolescencia, cada vez incorporando más clases. Siempre fueron días muy felices, el mejor'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'plan de todos los días era bailar.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'A medida que pasó el tiempo me di cuenta que esto iba cada vez tomando más importancia'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'para mí, comencé a asistir a Mayra en sus clases y empecé a sentir que ese transmitir me'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'generaba recibir mucho más de lo que daba, tanto en los grupos infantiles como adultas/os.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Y hoy en día trabajando como profesora sigo teniendo esa misma sensación, que es un ida y'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'vuelta constante, me da mucho placer charlar con cada persona, verlas crecer en la'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'actividad que elijan y tratar de generar ese espacio que nos llena en cuerpo, alma, mente y'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'corazón.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Estoy muy feliz de pertenecer al Estudio y de haber encontrado mi vocación a través de este'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'espacio.'},
                        ]}
                    }
                ],
                    bottom: [
                        {
                            text: { _type: 'TEXT', parts: [
                                { _type: 'STYLED_TEXT', color:'default', text: 'Y por aquí abajo te cuento un poco de mis estudios y trayectoria:'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '- Profesora e Intérprete en Danzas, Carrera de Formación Profesional, Escuela de'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'Danzas y Arte Reina Reech.'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '- Instructorado de Stretching.'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '- Comencé siendo asistente en Hadas, para luego seguir como Profesora titular en'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'Talleres Coreográficos, Clases de Danza y Stretching, con grupos de iniciación,'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'infantiles y adultas/os.'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '- Asisto a profesoras en 2do Año  y 3er Año de la Carrera De Formación Profesional'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'de la Escuela de Danzas y Arte Reina Reech en las clases de Danza Jazz y Danza'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'Contemporánea.'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '- Participación en Eventos de Cultura organizados por la Municipalidad de General'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'San Martin.'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '- Me desempeñé como Bailarina en la Compañía Hadas, Obras “Búsqueda” y'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: '“Mujeres Descalzas”, en la Compañía Neoclásica de la Escuela Reina Reech, y en'},
                                { _type: 'BREAK' },
                                { _type: 'STYLED_TEXT', color:'default', text: 'diversos Shows para eventos de Danza Jazz, Rock y Ritmos Latinos.'},
                            ]}
                        }
                    ]       
                },  
            {
                name: { _type: 'STRING', value: 'Lucila Ortiz' }, 
                image: { _type: 'IMAGE', link: 'profe_lucila.jpg' },
                header: {
                    image: { _type: 'IMAGE', link: 'header-default.jpg' },
                    title: { _type: 'STRING', value: 'LUCILA ORTIZ' }
                },
                title: { _type: 'TEXT', parts: [
                    { _type: 'STYLED_TEXT', color: 'bold', text: 'Lucila llegó a Hadas en 2012, es un placer compartir con ella tantos años.' },
                ]},
                stories: [
                    {
                        image: { _type: 'IMAGE', link: 'coachview-lucila-1.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Ella nos cuenta...'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'La acrobacia y la gimnasia son parte esencial de mi vida, de chica inventaba cintas de '},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'gimnasia con tela de raso que eran para el peinado del colegio y pasaba por la casa'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'haciendo medialunas de un lado para el otro.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'El primer contacto que tuve con la gimnasia artística fue en el colegio a los 6 años,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'comenzó como un juego para luego ir tomando cada vez más presencia en mi vida.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'A los 8 años me fracturé y tuve mucho miedo de volver, pero no era una opción dejar la gimnasia.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'A los 9 años entrenaba de lunes a sábado y también junto con mi entrenadora iba al Cenard,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'una de las experiencias más lindas que tuve. A ella, a mi entrenadora que tuve desde los 6'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'años, le voy a estar agradecida siempre, más allá de que mi familia es muy deportista, es'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'ella quien año a año me inculcó mucho de lo que hoy se como gimnasta y profesora, y un'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'ejemplo a seguir al decidir que quería esto para cada día de mi vida.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'De a poco fui sumando muchas actividades para seguir perfeccionándome y en esa'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'búsqueda encontré a mi profesor de gimnasia aeróbica, una disciplina que me gustó mucho'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'y seguí. Luego junto a ellos dos me sume a un montón de eventos de porristas, danza y gimnasia.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Un día me propusieron hacer un curso de acrobacia en tela, cuando probé subir a la tela dije'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'acá me quedo, desde ya con muchos miedos porque era algo desconocido pero la'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'experiencia fue fantástica y sabía que quería seguir aprendiendo más.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Finalmente llegó el día en el que hubo gente que confió en mí para darme el espacio de'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'enseñar y transmitir esto que tanto me apasiona. Siempre fue mucho el amor que tuve por'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'esta actividad, amo mi trabajo de profesora de la misma manera que amé de chica ser'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'alumna.'},
                        ]}
                    }
                ],
                bottom: [
                    {
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'default', text: 'Y por aquí abajo te cuento un poco de mis estudios y trayectoria:'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Profesorado de Educación Física, Instituto José Hernández, Villa Ballester.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Instructora en Musculación, Entrenadora Deportiva, Preparadora Física, Cicade.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Instructora de Pilates, Nativa Pilates, Belgrano.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Curso de Masajista, Daylo Plass, San Martín.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Curso de Acrobacia en Tela, Club Malcom, Palermo.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Curso pedagógico de Acrobacia en Tela, Escuela de circo SOQ, ciudad de Buenos Aires.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Me desempeño como Profesora de Acrobacia en Tela, Danza, Gimnasia Artística, Gimnasia Rítmica, Gimnasia Aeróbica y Natación.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: 'Instructora de Pilates y Spinning. Instructora en salón de musculación. Trabajo con grupos de iniciación, infantiles y adultas/os.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Profesora a cargo de Colonias de Verano y Preceptora en Colegio Hölters, Chilavert.'},
                        ]}
                    }
                ]
            },
            {
                name: { _type: 'STRING', value: 'Julieta Midun' },
                image: { _type: 'IMAGE', link: 'profe_julieta.jpg' },
                header: {
                    image: { _type: 'IMAGE', link: 'header-default.jpg' },
                    title: { _type: 'STRING', value: 'JULIETA MIDUN' }
                },
                title: { _type: 'TEXT', parts: [
                    { _type: 'STYLED_TEXT', color: 'bold', text: 'Julieta es una colega con la que hace muchos años venimos compartiendo shows en eventos y ahora es parte de Hadas.' },
                ]},
                stories: [
                    {
                        image: { _type: 'IMAGE', link: 'coachview-julieta-1.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Ella nos cuenta...'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'De chica buscaba ropa de mi mamá y de mi tía, armaba diferentes looks para usar según el'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'show. Mi mamá tenía un casete de Patricia Sosa que me encantaba, yo agarraba una maraca'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'y cantaba a full. En lo de mi tía, también sumaba alhajas y me maquillaba un montón, hacía'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'desfiles y mi abuela se encargaba de la filmación.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'De más grande, con mis amigas nos juntábamos y hacíamos representaciones de canciones'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'de María Elena Walsh, cada una tenía un personaje diferente, por supuesto con vestuarios y'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'coreografías armadas por nosotras. Por otro lado amaba jugar a la maestra, tenía un'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'pizarrón y ahí me la pasaba otras tantas horas.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Cuando tenía 7 años decidí ir a tomar clases de danza, era muy muy tímida, no hablaba'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'nada, pero por suerte después la danza me fue conquistando cada vez más, me enseñó a'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'soltarme y disfrutar. De adolescente me la pasaba haciendo videoclips de Arte en mi'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'habitación.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Finalmente la danza iba tomando más lugar en mis días y me fui dando cuenta que'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'claramente se convertía en mi pasión y lo que quería sea mi trabajo y forma de vida.'},
                        ]}
                    }         
                ],
                bottom: [
                    {
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'default', text: 'Y por aquí abajo te cuento un poco de mis estudios y trayectoria:'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- UBA- cursos extensión universitaria: Organización de Eventos,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Egresada de la fundación Julio Bocca: Intérprete en Comedia Musical.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Dance & Move: Carrera de Intérprete en Estilos Urbanos.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Estudio Ruben Viani: Taller de Teatro.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Participé en diferentes Eventos y Cursos como, Hit Tha Beat: Evento Mendoza, The King of Dance: Evento Córdoba,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: 'Dance & Move, Teatro San Martin, Espacio Abierto, Estudio Gallo Suelto, BLUR Make up & Effects,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: 'Factoria Cultural Recre- Arte, Escuela de Canto Kilya, Gonzalo Martinez Castro, Instituto Privado de Fitness'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: 'y Danzas Cynthia M. Pereyra, Domus de la Danza, I.U.N.A, Convención Internacional de danzas.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- “Pequeñas fiestas” Organización y animación de eventos infantiles, empresa personal.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: 'Participación en “La banda de Pituco”. Recreación en Fatlyf – Villa la Merced - Córdoba. Participación como Actriz, Animadora,'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: 'Coreógrafa y Bailarina en diferentes espectáculos y shows para eventos.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Profesora de Comedia Musical, Street, Urban, Danza Jazz y Hip Hop, para grupos infantiles y adultas/os.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Productora, Bailarina y Actriz de “(Lava tu cerebro con…) shampoooh”.'},
                        ]}
                    }
                ]
            },
            {
                name: { _type: 'STRING', value: 'Carola Cossi' },
                image: { _type: 'IMAGE', link: 'profe_carola.jpg' },
                header: {
                    image: { _type: 'IMAGE', link: 'header-default.jpg' },
                    title: { _type: 'STRING', value: 'CAROLA COSSI' }
                },
                title: { _type: 'TEXT', parts: [
                    { _type: 'STYLED_TEXT', color: 'bold', text: 'Carola se integró a Hadas en mi búsqueda de este estilo que me encanta.' },
                ]},
                stories: [
                    {
                        image: { _type: 'IMAGE', link: 'coachview-carola-1.jpg' },
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Ella nos cuenta...'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Siento que siempre tuve la danza en mí, fue y es mi forma más sincera de expresión.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'De chica aprovechaba que en el departamento que vivía tenía un espejo muy grande en el'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'living y cuando estaba sola ponía música y a bailar. Mi mamá empezó a notar esta pasión y'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'me llevó a mis primeras clases de danza, la verdad me pasó que no me sentía cómoda con'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'el estilo y por varios años dejé las clases.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'A pesar de esto, en los actos del colegio, con mis amigas bailábamos y yo me encargaba'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'desde los 6 años de armar gran parte de esas coreografías. La danza seguía'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'acompañándome sólo que todavía no tenía bien en claro que estilo quería aprender.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'En la adolescencia me encontré por casualidad con una clase de Salsa y Bachata y me'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'enamoré del estilo. El profesor que me recibió fue quien luego me acompañó en todo este'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'camino de Ritmos Latinos.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Ese fue mi punto de partida y más adelante me empezaron a conquistar las danzas de'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'Bollywood, al principio veía videos y luego me puse a investigar hasta que encontré una'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'profesora que dictaba esta clase. Ambas danzas son muy diferentes pero es el día de hoy'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'que me siento muy identificada con cada una. Más adelante seguí perfeccionándome con'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'otras disciplinas, manteniendo siempre esta base.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'La danza es un lenguaje universal, puede variar su técnica según cada cultura, pero a pesar'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'de tener sus diferencias la danza se puede compartir en comunidad, y eso es algo que valoro'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color: 'bold', text: 'y disfruto mucho.'},
                        ]}
                    }
                ],
                bottom: [
                    {
                        text: { _type: 'TEXT', parts: [
                            { _type: 'STYLED_TEXT', color:'default', text: 'Y por aquí abajo te cuento un poco de mis estudios y trayectoria:'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Curso anual de formación de instructores (CAFI). Título obtenido: Instructora de Salsa.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Formación en Salsa y Bachata. Ricki Diaz.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Curso de formación en Ritmos Urbanos. Bayres Escuela de Ritmos.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Formación en Bollywood Dance. Escuela de Danza y Música de Medio Oriente.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Estudio de Danza Clásica de India Baratha Natyam. Gungur, Indian Fine Arts.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Me desempeño como Coreógrafa, Directora y Bailarina en Alo Crew.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Profesora de Bollywood Dance, Twerk y Salsa y Bachata.'},
                            { _type: 'BREAK' },
                            { _type: 'STYLED_TEXT', color:'default', text: '- Bailarina en diferentes Eventos y Festivales de la India en Buenos Aires.'},
                        ]}
                    }
                ]
            }
        ] 
    },
    alumnos: {
        header: { _type: 'STRING', value: 'ALUMNAS DICEN' },
        items: [
            {
                image: { _type: 'IMAGE', link: 'testimoniales-1.png' }
            },
            {
                image: { _type: 'IMAGE', link: 'testimoniales-2.png' }
            },
            {
                image: { _type: 'IMAGE', link: 'testimoniales-3.png' }
            },
            {
                image: { _type: 'IMAGE', link: 'testimoniales-4.png' }
            }
        ] 
    },
    contacto: {
        header: { _type: 'STRING', value: '¿CÓMO ME INSCRIBO?' },
        main: {
            text : { _type: 'TEXT', parts: [
                { _type: 'STYLED_TEXT', color:'bold', text:'Escribinos y contanos en qué podemos ayudarte.'},
                { _type: 'BREAK' },
                { _type: 'RAW_TEXT', text:'Puede ser por '}, { _type: 'LINK_TEXT', color:'secondary', link:'https://web.whatsapp.com/send?phone=5491140222619', text:'WhatsApp '},
                { _type: 'RAW_TEXT', text:'o al mail '}, { _type: 'LINK_TEXT', color:'secondary', link:'mailto:info.hadasestudiodedanza@gmail.com', text:'info.hadasestudiodedanza@gmail.com'}
            ]},
            bottomText: { _type: 'TEXT', parts: [
                { _type: 'STYLED_TEXT', color:'bold', text:'¡VENITE 10 MINUTOS ANTES DE LA PRIMERA CLASE PARA PRESENTARNOS E INSCRIBIRTE!'},
            ]},
        },
        form: {
            title: { _type: 'STRING', value: 'CONTACTO' }
        },
        address: { _type: 'LINK', url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.884151423687!2d-58.565737884921056!3d-34.556488480471806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb9fa4f09b193%3A0xead9a1702ce25e2e!2sHadas%20Estudio%20de%20Danza!5e0!3m2!1sen!2sar!4v1604013901531!5m2!1sen!2sar" },
        instagram: { _type: 'LINK', url: 'https://cdn.curator.io/published/2c785a6d-f054-4a3e-aee5-b5f005f27e90.js' },
        facebook: { _type: 'LINK', url: 'https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FHadas-Estudio-de-Danza-Mayra-Malfitano-144710126050518%2F&tabs=timeline&width=245&height=70&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId" width="280" height="130" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share' },
        governmentLogo: { _type: 'IMAGE', link: 'government-logo.jpg' }, 
    }
}
*/